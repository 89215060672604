interface InitializersState {
    finished: number;
    total: number;
}

const DEFAULT_STATE: InitializersState = {
    finished: 0,
    total: 0,
};

const getObservableStateProxy = (stateObj: InitializersState, onFinishedCallback: () => void): InitializersState => {
    const changeHandler: ProxyHandler<InitializersState> = {
        set(target, prop, value) {
            target[prop as keyof InitializersState] = value;
            if (prop === 'finished' && target.finished === target.total && target.total > 0) {
                onFinishedCallback();
            }
            return true;
        },
    };
    return new Proxy(stateObj, changeHandler);
};

class InitializerService {
    private state: InitializersState;

    checkInitializers(onFinishedCallback: () => void) {
        this.state = getObservableStateProxy(DEFAULT_STATE, onFinishedCallback);
        const allInitializers: NodeListOf<HTMLElement> = document.querySelectorAll('[data-js-init]');

        if (allInitializers.length > 0) {
            this.state.total = allInitializers.length;
            allInitializers.forEach((initializer) => {
                this.runInitializer(initializer);
            });
        } else {
            onFinishedCallback();
        }
    }

    runInitializer(initializer: HTMLElement) {
        const initializerName = initializer.dataset.jsInit;

        const initializersMap: Record<string, string> = {
            'header-mobile': 'header-mobile',
            'global-search': 'global-search',
            'meta-navigation': 'meta-navigation',
            'main-navigation': 'main-navigation',
            'footer-navigation': 'footer-navigation',
            swiper: 'swiper-factory',
            tabs: 'tabs',
            'anchor-smooth-scroll': 'anchor-smooth-scroll',
            'suggest-input': 'suggest-input',
            'skip-links': 'skip-links',
            'skip-over-links': 'skip-over-links',
            woerterbuch: 'woerterbuch',
            'action-layer': 'action-layer',
            'beratungsstellen-suggest': 'beratungsstellen-suggest',
            'regionalpartner-profil-dropdown': 'regionalpartner-profil-dropdown',
            map: 'map',
            'toast-handler': 'toast-handler',
            tooltip: 'tooltip',
            feedback: 'feedback',
            'adressverwaltung-liste-handler': 'adressverwaltung-liste-handler',
            'aktualisierungsanfrage-handler': 'aktualisierungsanfrage-handler',
            'materialbestellung-error-handler': 'materialbestellung-error-handler',
            'friendly-challenge': 'friendly-challenge',
            'search-form-submit-type-tracking': 'search-form-submit-type-tracking',
            ckeditor: 'ckeditor',
            'duplicates-popup': 'duplicates-popup',
            'address-search-list-map-toggle': 'address-search-list-map-toggle',
            'address-search-map': 'address-search-map',
            'regionalpartner-map': 'regionalpartner-map',
            'familienratgeber-chat': 'familienratgeber-chat',
            'familienratgeber-chat-opener': 'familienratgeber-chat-opener',
        };

        const modulePath = initializersMap[initializerName];

        if (modulePath) {
            import(`../components/${modulePath}.ts`).then((module) => {
                if (initializerName === 'swiper') {
                    module.default.initSwiperOnElement(initializer);
                } else if (initializerName === 'friendly-challenge') {
                    // No init function needed, just increase the state
                } else {
                    module.default.init(initializer);
                }
                this.state.finished++;
            });
        } else {
            console.warn(`InitializerService: No initializer found for "${initializerName}" via DOM element:`, initializer);
            this.state.finished++;
        }
    }
}

export default new InitializerService();
